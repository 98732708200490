<template>


    <b-row>
     <b-col
        md="12"
        lg="12"
        >
        <b-card
          class="text-center"
        >

        <h1>How To Create an API Key - Video Tutorial</h1>
          <b-card-text>
           Please watch the following video to create a Google API key. Without this, the software won't work.
          </b-card-text>
          <br>
          <br>
          <!-- <b-card-text>
           Access Google API Console here: <a href="" class="font-weight-bold" target="_blank"> CLICK HERE </a>
          </b-card-text> -->
          <!-- <br>
          <br> -->

          <div>
            <iframe width="560" height="315" src="https://www.youtube.com/embed/S6F-YuQhgM4" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>

          <br>
          <br>

          <h1>
          How To Enable Google Cloud Billing
          </h1>
          <b-card-text>
        Necessary step, no costs involved if you don't spam
        </b-card-text>

        <br>
        <br>

          <div>
            <iframe width="560" height="315" src="https://www.youtube.com/embed/lDexLa5Qx_s" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>

          <br>
          <br>
        <div>
        <h1>
          Google API Key
          </h1>
          <validation-observer ref="apiKeyValidation">
            <b-form @submit.prevent="validationForm">

                <validation-provider
                    #default="{ errors }"
                    name="api_key"
                    rules="required"
                    vid="vi-apikey"
                >
                    <b-form-input
                        v-if="user?.api_key"
                        id="apikey"
                        type="text"
                        name="api_key"
                        v-model="apikey"

                        placeholder="*******************************"
                    />
                    <b-form-input
                        v-else
                        id="apikey"
                        type="text"
                        name="api_key"
                        v-model="apikey"
                        placeholder="ENTER API KEY"
                    />
                </validation-provider>
                    <br>
                    <br>
                <template v-if="isApiKeyFormSubmitLoading">
                <b-button variant="secondary" disabled>
                    <b-spinner small />
                    Loading...
                </b-button>
                </template>

                <!-- submit button -->
                

                <template v-else-if="user?.api_key">
                <b-button
                    type="submit"

                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="warning"
                    :disabled = "apiKeyUpdateButton"
                >
                    Update API Key
                </b-button>
                </template>

                <template v-else>
                <b-button
                    type="submit"

                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="secondary"
                    :disabled = "apiKeyUpdateButton"

                >
                    Save API Key
                </b-button>
                </template>

            </b-form>
            </validation-observer>
        </div>
        <br>
        </b-card>
      </b-col>

    </b-row>

</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardBody, BRow, BCol, BFormGroup, BFormInput, BButton, BForm, BCardText, BSpinner } from 'bootstrap-vue'
import Cleave from 'vue-cleave-component'
import Ripple from 'vue-ripple-directive'
import { mapGetters } from "vuex";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, min, confirmed } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    Cleave,
    BButton,
    BCardText,
    ValidationObserver,
    ValidationProvider,
    BSpinner,
    ToastificationContent
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      apikey:'',
      isApiKeyFormSubmitLoading:false,
      apiKeyUpdateButton: true,
    }
  },
  watch: {
    apikey: function(newVal, oldVal) {
      if(newVal.length > 0) {
        this.apiKeyUpdateButton = false;
      }else{
        this.apiKeyUpdateButton = true;
      }
  },
  },
  computed: {
    ...mapGetters({
      user: "userModule/getUser",
      permissions: "userModule/getPermissions",
    }),
    avatar() {
      return require("@/assets/images/avatars/avatar.webp");
    },
  },
  methods:{
    validationForm: async function () {

        this.$refs.apiKeyValidation.validate().then( async (success)=>{
            if(success){
                try{
                    this.isApiKeyFormSubmitLoading = true;
                    const res = await this.$api.post(
                    "/api/user/api_key/store2",
                    {
                        api_key: this.apikey
                    });
                    //this.isApiKeyFormSubmitLoading = false;


                    const data = res?.data;

                    const resUser = await this.$api.post(
                      "/api/user?include=permissions,designation,roles,department"
                    );

                    const { permissions, ...user } = resUser?.data?.data;
                    await this.$store.dispatch("userModule/setUser", {
                      user,
                    });

                    //this.resetForm();
                    this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: "Success",
                      icon: "BellIcon",
                      variant: "success",
                      text: "API KEY successfully updated",
                    },
                  });
                  this.resetForm();
                  this.isApiKeyFormSubmitLoading = false;
                }catch (error) {
                  this.isApiKeyFormSubmitLoading = false;
                  if (error?.response?.data?.message) {
                    this.$toast({
                      component: ToastificationContent,
                      props: {
                        title: "Error",
                        icon: "BellIcon",
                        variant: "danger",
                        text: error?.response?.data?.message,
                      },
                    });
                  }
                  if (error?.response?.data?.errors) {
                    this.$refs.apiKeyValidation.setErrors(
                      error?.response?.data?.errors
                    );
                  }
                }

            }
        })
    }
  },
}
</script>
